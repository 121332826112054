'use strict';

var base = require('base/login/login');
const formValidation = require('base/components/formValidation');
var notification = require('../components/notification');
const clientSideValidation = require('../components/clientSideValidation');


/**
 * Masks a phone number by replacing all characters except for the first `visibleCharsInStart`
 * and last `visibleCharsInEnd` with asterisks.
 *
 * @param {string} phoneNumber the phone number to mask
 * @returns {string} the masked phone number
 */
function maskPhone(phoneNumber) {
    let visibleCharsInStart = 3;
    let visibleCharsInEnd = 3;
    // eslint-disable-next-line no-param-reassign
    phoneNumber = phoneNumber.substring(0, visibleCharsInStart) + '*'.repeat(phoneNumber.length - visibleCharsInStart - visibleCharsInEnd) + phoneNumber.substring(phoneNumber.length - visibleCharsInEnd);

    return phoneNumber;
}

/**
 * Masks an email address by replacing all characters except for the first
 * `visibleCharsInStart` characters of the local part with asterisks.
 *
 * @param {string} email the email address to mask
 * @returns {string} the masked email address
 */
function maskEmail(email) {
    let visibleCharsInStart = 1;
    let emailParts = email.split('@');

    let maskedPart = email.substring(0, visibleCharsInStart) + '*'.repeat(emailParts[0].length - visibleCharsInStart);

    return maskedPart + email.replace(/[^@]+([@\w\W]+)/g, '$1');
}

/**
 * Displays the login modal, resetting its state and showing it to the user.
 *
 * If the modal HTML is already present in the page, it will be shown immediately.
 * Otherwise, an AJAX request will be made to retrieve the modal HTML and then show it.
 *
 * @return {void}
 */
function showLoginModal() {
    $('.login-without-error').removeClass('d-none');
    $('.login-with-error').addClass('d-none');
    var $loginModal = $('#loginModal');
    var $body = $('body');
    if ($loginModal && $loginModal.length > 0) {
        var $otpInput = $('#login-form-otp');
        $otpInput.parents('div.form-group').addClass('d-none').removeClass('required form-group-filled');
        $otpInput.attr('required', false).removeClass('filled');
        $('.login-information-top').removeClass('d-none');
        $('#resendSMSCode').addClass('d-none').removeClass('d-inline-block');
        $('.login-without-error .login-popup-text').html('<span>' + $('.login-without-error .login-popup-text').data('default-message') + '</span>');

        $loginModal.find('.form-group').removeClass('form-group-filled');
        $loginModal.find('input').not('*[name=csrf_token]')
            .removeClass('filled')
            .removeClass('is-invalid')
            .val('');
        $loginModal.modal('show');
        $body.trigger('loginmodal:ready');
    } else {
        $.ajax({
            url: $(this).attr('href'),
            type: 'get',
            dataType: 'json',
            success: function (data) {
                if (data.html) {
                    $body.append(data.html);
                    $('#loginModal').modal('show');
                    $body.trigger('loginmodal:ready');
                }
            }
        });
    }
}

/**
 *
 * @param {Object} data - response JSON
 */
function processOTP(data) {
    let $loginFormOPT = $('#login-form-otp');
    let $loginPopupText = $('.login-without-error .login-popup-text');
    let $resendSMSCode = $('#resendSMSCode');

    $('.login-information-top').addClass('d-none');
    $loginFormOPT.parents('div.form-group').removeClass('d-none').addClass('required');
    $loginFormOPT.attr('required', 'required');
    $('#loginEmail').val(data.email);
    $('#smsPhoneNumber').val(data.phoneNumber);
    $resendSMSCode.removeClass('d-none').addClass('d-inline-block');
    let html = '<span>שלחנו קוד אימות</span>';
    if (data.phoneNumber) {
        html += '<span>ב-SMS למספר: <span class="phone ltr">' + maskPhone(data.phoneNumber) + '</span></span>';
    }
    if ($('#loginEmail').val()) {
        html += '<span>ובדוא"ל לכתובת: ' + maskEmail($('#loginEmail').val()) + '</span>';
    }
    $loginPopupText.html(html);
    clientSideValidation.functions.disableUntilValid($('.login'), $('button.popup-login'));

    if (data && data.message) {
        $('.resend-otp-success').remove();
        $('<p class="resend-otp-success rtl">' + data.message + '</p>').insertBefore('.resend-sms-code');
        $resendSMSCode.attr('disabled', 'disabled');
        $resendSMSCode.addClass('disabled');
        setTimeout(function () {
            $('.resend-otp-success').remove();
            $resendSMSCode.removeAttr('disabled', 'disabled');
            $resendSMSCode.removeClass('disabled');
        }, 60000);
    }

    setTimeout(function () {
        $($('.login').find('input:visible').get(0)).focus();
    }, 750);
}

/**
 * Initializes the login functionality.
 *
 * Checks for the presence of the login button and the availability of the login modal.
 * Binds event listeners for showing the login modal.
 *
 * @return {undefined}
 */
base.init = function () {
    var $loginBtn = $('#loginBtn');

    if ($loginBtn && $loginBtn.length > 0 && window.app && window.app.isLoginModalAvailable) {
        showLoginModal.call($loginBtn);
    }
    $('body').on('showLoginModal', function () {
        showLoginModal.call($loginBtn, true);
    });

    $(document).on('click', '#loginBtn, .loginBtn', function (e) {
        e.preventDefault();
        if ($loginBtn.length > 0) {
            let redirectURL = $(this).attr('data-redirect-url');
            showLoginModal.call($loginBtn.get(0));
            if (redirectURL) {
                $('body').on('loginmodal:ready', function () {
                    $('#loginRedirectUrl').val(redirectURL);
                });
            }
        }
        return false;
    });

    $(document).on('click', '#loginModal button.close', function () {
        if (window.location.pathname !== window.app.URL.HOME_PAGE_URL) {
            window.location.href = window.app.URL.HOME_PAGE_URL;
        }
    });
};

/**
 * Displays the login error screen.
 *
 * @param {object} form - The login form object.
 * @param {object} data - The error data object.
 * @return {void}
 */
let showErrorLoginScreen = function (form, data) {
    $('.login-without-error').addClass('d-none');
    $('.login-with-error').removeClass('d-none');
    formValidation(form, data);
    $('form.login').trigger('login:error', data);
};

/**
 * Handles the login functionality by sending an AJAX request to the server.
 *
 * @return {void}
 */
base.login = function () {
    $(document).on('submit', 'form.login', function (e) {
        var form = $(this);
        e.preventDefault();
        var url = form.attr('action');
        $('form.login').trigger('login:submit', e);
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: form.serialize(),
            beforeSend: form.spinner().start,
            complete: form.spinner().stop,
            success: function (data) {
                if (!data.success) {
                    if (Object.prototype.hasOwnProperty.call(data, 'loginAttemptsExceeded')) {
                        if (data.loginAttemptsExceeded) {
                            showErrorLoginScreen(form, data);
                        } else {
                            $('#login-form-otp').addClass('is-invalid');
                            $('#login-form-otp').closest('div.form-group').removeClass('form-group-filled');
                            $('#form-otp-error').text(data.message);
                        }
                    } else {
                        showErrorLoginScreen(form, data);
                        if (data.redirectUrl) {
                            window.location.href = data.redirectUrl;
                        }
                    }
                } else if (data.html) {
                    $('#loginModal').modal('hide');
                    $('body').append(data.html);
                    $('#selectOrganizationModal').modal('show');
                    $('#selectOrganizationModal').on('hidden.bs.modal', function () {
                        window.location.href = $('#selectOrganizationModal').find('.modal-body a:first').attr('href');
                    });
                } else if (data.otp) {
                    processOTP(data);
                } else {
                    $('form.login').trigger('login:success', data);
                    location.href = data.redirectUrl;
                }
            },
            error: function (data) {
                if (data.responseJSON.redirectUrl) {
                    window.location.href = data.responseJSON.redirectUrl;
                } else {
                    notification.createErrorNotification(data.errorMsg);
                    $('form.login').trigger('login:error', data);
                }
            }
        });
        return false;
    });
};

/**
 * Handles the event when the "resendSMSCode" button is clicked.
 *
 * @param {Event} e - The event object representing the click event.
 * @return {boolean} Returns false to prevent the default behavior of the event.
 */
base.resendSMSCode = function () {
    $(document).on('click', '#resendSMSCode', function (e) {
        e.preventDefault();
        var $this = $(this);
        if ($this.attr('disabled')) {
            return true;
        }
        var form = $this.closest('form');
        var url = $this.attr('href');
        $('form.login').trigger('login:submit', e);
        $('#login-form-otp').val('');
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: form.serialize(),
            beforeSend: form.spinner().start,
            complete: form.spinner().stop,
            success: function (data) {
                if (!data.success) {
                    formValidation(form, data);
                    notification.createErrorNotification(data.errorMsg);
                    $('form.login').trigger('login:error', data);
                } else if (data.otp) {
                    processOTP(data);
                }
            },
            error: function (data) {
                if (data.responseJSON.redirectUrl) {
                    window.location.href = data.responseJSON.redirectUrl;
                } else {
                    notification.createErrorNotification(data.errorMsg);
                    $('form.login').trigger('login:error', data);
                }
            }
        });
        return false;
    });
};

module.exports = base;
